import { Popup } from 'Lib/popup/popup';
import { eventSender } from 'Lib/event-sender/event-sender';

const elPopup = document.querySelectorAll('.popup-subscribe');

elPopup.forEach((item) => {
    const elBtn = item.querySelector('.popup-subscribe__btn');

    elBtn.addEventListener('click', () => {
        const popup = new Popup('popup', document.querySelector('.popup-subscribe__popup'));

        eventSender.customYM({
            page: {
                'link': window.location.href,
                'title': document.querySelector('title').textContent.trim(),
            },
            element: {
                'text': elBtn.textContent.trim(),
                'class': 'popup-subscribe__btn',
            },
            event: 'click',
        });

        popup.open();
    });
});
